import * as Msal from "@azure/msal-browser";

class Auth {
  #handleRedirectPromise = null;
  constructor() {
    this.accessToken = null;
    this.accountId = null;
    this.authDomain = process.env.REACT_APP_AUTH_DOMAIN;
    this.authFlow = "B2C_1_SignUpSignIn";
    this.profileFlow = "B2C_1_ProfileEdting";
    this.postLogoutRedirectUrl = process.env.REACT_APP_AUTH_POST_LOGOUT_URL;
    this.authorityUrl = process.env.REACT_APP_AUTH_URL;
    this.clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
    let redirectUrl = process.env.REACT_APP_AUTH_REDIRECT_URL;
    
    this.msalConfig = {
      auth: {
        clientId: this.clientId,
        authority: this.authorityUrl + this.authFlow,
        validateAuthority: true,
        redirectUri: redirectUrl,
        postLogoutRedirectUri: this.postLogoutRedirectUrl,
        navigateToLoginRequestUrl: true,
        knownAuthorities: [this.authDomain]
      },
      cache: {
        cacheLocation: "localStorage", // "sessionStorage" is more secure but "localStorage" gives you SSO between brower tabs
        storeAuthStateInCookie: false
      }
    };

    this.msalInstance = new Msal.PublicClientApplication(this.msalConfig);    

    this.#handleRedirectPromise = this.msalInstance.handleRedirectPromise()
        .then(response => {
            if (response) {
                var authContext = (response.idTokenClaims['tfp'] || response.idTokenClaims['tcr'] || "").toUpperCase();

                if (authContext === this.authFlow.toUpperCase()) {
                    this.handleAuthResponse(response);
                }
            }
        })
        .catch(error => {
            console.log(error);
        });
  }

  afterRedirectHandled(task, onError) {
      this.#handleRedirectPromise.then(task, onError);
  }

  async handleAuthResponse(response) {
    if (response !== null) {
        this.accessToken = response.accessToken || response.idToken;
        this.setAccount(response.account);
    } else {
        this.selectAccount();
    }
  }

  setAccount(account) {
    this.accountId = account.homeAccountId;

    let user = {
      firstName: "Unknown",
      lastName: "User",
      userId: "",
      email: ""
    }

    if (account.idTokenClaims !== undefined) {
      user.firstName = account.idTokenClaims.given_name;
      user.lastName = account.idTokenClaims.family_name;
      user.userId = account.idTokenClaims.extension_UserID;
      user.email = account.idTokenClaims.emails.length > 0 ? account.idTokenClaims.emails[0] : "";
    }

    this.dispatch('SignedIn', user);
  }

  selectAccount() {
    const currentAccounts = this.msalInstance.getAllAccounts();

    if (currentAccounts.length < 1) { 
        this.dispatch('SignedOut', {});
        return false;
    } else if (currentAccounts.length > 1) {
        const accounts = currentAccounts.filter(account =>
            account.homeAccountId.toUpperCase().includes(this.authFlow.toUpperCase()) &&
            account.idTokenClaims.iss.includes(this.authDomain) &&
            account.idTokenClaims.aud === this.msalConfig.auth.clientId 
        );

        if (accounts.length > 1) {
            if (accounts.every(account => account.localAccountId === accounts[0].localAccountId)) {
                // All accounts belong to the same user         
                this.setAccount(accounts[0]);
            } else {
                // Multiple users detected. Logout all to be safe
                this.signOut();
                return false;
            };
        } else if (accounts.length === 1) {
            this.setAccount(accounts[0]);
        }
    } else if (currentAccounts.length === 1) {
        this.setAccount(currentAccounts[0]);
    }

    return true;
  }

  async getAccessToken() {
    if (this.accessToken) return Promise.resolve(this.accessToken);

    const tokenRequest = {
        scopes: [],
        forceRefresh: false,
        account: this.msalInstance.getAccountByHomeId(this.accountId)
    };
   
    return this.msalInstance.acquireTokenSilent(tokenRequest)
        .then((response) => {
            // In case the response from B2C server has an empty accessToken field
            // throw an error to initiate token acquisition
            this.accessToken = response.accessToken || response.idToken || "";

            if (this.accessToken === "") {
                throw new Msal.InteractionRequiredAuthError();
            } else {
                return this.accessToken;
            }
        }).catch(error => {
            console.log("Silent token acquisition fails. Acquiring token using popup. \n", error);
            if (error instanceof Msal.InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                return this.msalInstance.acquireTokenRedirect(tokenRequest);
            } else {
                console.log(error);   
            }
    });
  }

  signIn() { 
    const loginRequest = { scopes: ["openid"], prompt: "select_account" }; 
    this.msalInstance.loginRedirect(loginRequest);
  }

  signOut() {
    const logoutRequest = {
        postLogoutRedirectUri: this.postLogoutRedirectUrl,
        mainWindowRedirectUri: this.postLogoutRedirectUrl
    };
    this.msalInstance.logoutRedirect(logoutRequest);
  }

  editProfile() {
    const editProfileRequest = {
        authority: this.authorityUrl + this.profileFlow,
        loginHint: this.msalInstance.getAccountByHomeId(this.accountId).username
    };
    this.msalInstance.loginRedirect(editProfileRequest);
  }

  // allow controls to subscribe to events
  on(name, callback) {
    var callbacks = this[name];
    if (!callbacks) {
      this[name] = [callback];
    } else {
      callbacks.push(callback);
    }
  }

  // emit events to subscribers
  dispatch(name, event) {
    const callbacks = this[name];
    if (callbacks) callbacks.forEach(callback => callback(event));
  }  
}

const auth = new Auth();
export default auth;
