export const topBarHeight = 64;
export const sideNavWidth = 260;
export const navbarHeight = 60;
export const sidenavCompactWidth = 80;
export const containedLayoutWidth = 1200;

export const providerList = [
    { name: "Middleware Bank 1", provider: "MWB1", icon: "middleware.svg", enabled: true },
    { name: "Middleware Bank 2", provider: "MWB2", icon: "middleware.svg", enabled: true },
    { name: "ANZ", provider: "ANZ", icon: "anz.svg", enabled: false },
    { name: "ASB", provider: "ASB", icon: "asb.svg", enabled: false },
    { name: "BNZ", provider: "BNZ", icon: "bnz.svg", enabled: false },
    { name: "Heartland Bank", provider: "HEARTLAND", icon: "heartlandbank.svg", enabled: false },
    { name: "Kiwibank", provider: "KIWIBANK", icon: "kiwibank.svg", enabled: false },
    { name: "TSB", provider: "TSB", icon: "tsb.svg", enabled: false },
    { name: "Westpac", provider: "WESTPAC", icon: "westpac.svg", enabled: false }
];

export const merchantCategoryCodes = [
    { name: "Airlines", start: 3000, end: 3299, icon: "flight" },
    { name: "Car Rental", start: 3300, end: 3499, icon: "carrental" },
    { name: "Lodging", start: 3500, end: 3999, icon: "hotel" },
    { name: "Agricultural Services", start: 1, end: 1499, icon: "agriculture" },
    { name: "Contracted Services", start: 1500, end: 2999, icon: "handyman" },
    { name: "Transportation Services", start: 4000, end: 4799, icon: "directionsbus" },
    { name: "Utility Services", start: 4800, end: 4999, icon: "phone" },
    { name: "Retail Outlet Services", start: 5000, end: 5599, icon: "shoppingbasket" },
    { name: "Clothing Stores", start: 5600, end: 5699, icon: "store" },
    { name: "Miscellaneous Stores", start: 5700, end: 7299, icon: "storefront" },
    { name: "Business Services", start: 7300, end: 7999, icon: "sell" },
    { name: "Professional Services and Membership Organizations", start: 8000, end: 8999, icon: "circle" },
    { name: "Government Services", start: 9000, end: 9999, icon: "assuredworkload" }
];
