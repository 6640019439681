import { useRoutes } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { MatxTheme } from "./components";
import { AuthProvider } from "./contexts/JWTAuthContext";
import SettingsProvider from "./contexts/SettingsContext";
import routes from "./routes";
import store from './redux/Store';
import { Provider } from 'react-redux'
import { SnackbarProvider } from "./components/Snackbar/SnackbarProvider";

export default function App() {
  const content = useRoutes(routes);

  return (
    <Provider store={store}>
      <SettingsProvider>
        <AuthProvider>
          <MatxTheme>
            <SnackbarProvider>
              <CssBaseline />
              {content}
            </SnackbarProvider>
          </MatxTheme>
        </AuthProvider>
      </SettingsProvider>
    </Provider>
  );
}
