import { lazy } from "react";
import { Navigate } from "react-router-dom";

import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";

import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";

const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
const Analytics = Loadable(lazy(() => import("app/views/dashboard/Analytics")));
const Consents = Loadable(lazy(() => import("app/views/consents/Consents")));
const UpcomingPayments = Loadable(lazy(() => import("app/views/upcoming-payments/UpcomingPayments")));
const Transaction = Loadable(lazy(() => import("app/views/transactions/Transaction")));
const Transactions = Loadable(lazy(() => import("app/views/transactions/Transactions")));
const Rates = Loadable(lazy(() => import("app/views/rates/Rates")));
const Warranties = Loadable(lazy(() => import("app/views/warranties/AllWarranties")));

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      { path: "/home", element: <Analytics />, auth: authRoles.guest },
      { path: "/consents", element: <Consents />, auth: authRoles.guest },
      { path: "/upcoming-payments", element: <UpcomingPayments />, auth: authRoles.guest },
      { path: "/transactions", element: <Transactions />, auth: authRoles.guest },
      { path: "/transactions/:transaction_id", element: <Transaction />, auth: authRoles.guest },
      { path: "/rates", element: <Rates />, auth: authRoles.guest },
      { path: "/warranties", element: <Warranties />, auth: authRoles.guest },
    ],
  },

  // session pages route
  { path: "/session/404", element: <NotFound /> },
  { path: "/session/signin", element: <JwtLogin /> },

  { path: "/", element: <Navigate to='home' /> },
  { path: "*", element: <NotFound /> },
];

export default routes;