import { createContext, useEffect, useReducer } from "react";
import auth from "../utils/Auth";

// CUSTOM COMPONENT
import { MatxLoading } from "app/components";

const initialState = {
  user: null,
  isInitialized: false,
  isAuthenticated: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialized: true, user };
    }

    case "LOGIN": {
      return { ...state, isAuthenticated: true, user: action.payload.user };
    }

    case "LOGOUT": {
      return { ...state, isAuthenticated: false, user: null };
    }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => { },
  loginSuccess: () => { }
})

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const login = () => {
      auth.signIn();
  }

  const loginSuccess = (user) => {
      dispatch({
          type: 'LOGIN',
          payload: { user, }
      });
  }

  const logout = () => {
      auth.signOut();        
      dispatch({ type: 'LOGOUT' });        
  }

  useEffect(() => {
      auth.afterRedirectHandled(() => {
          dispatch({
              type: 'INIT',
              payload: {
                  isAuthenticated: false,
                  user: null,
              },
          });
      });
  }, []);

  // SHOW LOADER
  if (!state.isInitialized) return <MatxLoading />;

  return (
    <AuthContext.Provider value={{ ...state, method: "JWT", login, logout, loginSuccess }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
