import { Alert, Snackbar, styled } from '@mui/material';
import React, { createContext, useReducer, useContext } from 'react'

const SnackbarContext = createContext();

const CancelButton = styled("button")({
    border: "none",
    marginLeft: "1.5em",
    background: "transparent",
    paddingInline: "1em",
    borderRadius: "5px",
    ":hover": {
        background: "rgba(0, 0, 0, 0.04)"
    }
});

const snackbarReducer = (state, action) => {
    switch (action.type) {
        case 'OPEN_SNACKBAR':
            return {
                ...state,
                open: true,
                message: action.payload.message,
                severity: action.payload.severity
            }
        case 'CLOSE_SNACKBAR':
            return {
                ...state,
                open: false
            }
        default:
            return state
    }
}

export function SnackbarProvider({ children }) {
    const [state, dispatch] = useReducer(snackbarReducer, { open: false, message: '', severity: 'info' });
    const [showCancel, setShowCancel] = React.useState(false);
    const [canceled, setCanceled] = React.useState(false);

    const showSnackbar = (message, severity = 'info', shouldShowCancel = false) => {
        setShowCancel(shouldShowCancel);
        dispatch({ type: 'OPEN_SNACKBAR', payload: { message, severity } });
    }

    const hideSnackbar = () => {
        setShowCancel(false);
        dispatch({ type: 'CLOSE_SNACKBAR' });
    }

    const onCancel = () => {
        setCanceled(true);
    }

    const cancelConsumed = () => {
        setCanceled(false);
        setShowCancel(false);
    }

    return (
        <SnackbarContext.Provider value={{ showSnackbar, hideSnackbar, cancelConsumed, canceled }}>
            {children}
            <Snackbar
                open={state.open}
                autoHideDuration={6000}
                onClose={hideSnackbar}
                ClickAwayListenerProps={{ onClickAway: () => null }}
            >
                <Alert onClose={hideSnackbar} severity={state.severity}>
                    {state.message}

                    {showCancel && <CancelButton onClick={onCancel} >Cancel</CancelButton>}
                </Alert>
            </Snackbar>
        </SnackbarContext.Provider>
    )
}

export const useSnackbar = () => useContext(SnackbarContext);
